.usersSection {
    width: 90%;
    max-width: 800px;
    margin: 2rem auto;
    background: #fff;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
}

.sortAndSearchContainer {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
}

.searchInput {
    flex: 1;
    min-width: 200px;
    max-width: 300px;
    height: 3rem;
    padding: 0 1rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.searchInput:focus {
    outline: none;
    border-color: #22e06c;
}

.userListBox {
    height: 400px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding-block: 1rem;
}

.userRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-inline: 1rem;
}

.userRow:hover {
    background-color: #f0f0f0;
}

.userEmail {
    font-weight: 500;
    font-size: 1rem;
    word-break: break-all;
}

.registeredAt {
    font-size: 0.95rem;
    color: #555;
}

.noUsers {
    text-align: center;
    margin-top: 2rem;
    color: #888;
    font-style: italic;
    top: 100px;
    position: relative;
}

.userValue {
    margin-left: 1rem;
    font-size: 0.9rem;
    color: #555;
}

.checkboxContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding-inline: 1rem;
    flex-wrap: wrap;
}

.nlBox {
    display: flex;
    gap: .5rem;
    justify-content: center;
    align-items: center;
}

.gclidLabel {
    background-color: #12B3F8;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 13px;
}